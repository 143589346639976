@import "~@egjs/react-flicking/dist/flicking-inline.css";
@import "~@egjs/flicking/dist/flicking.css";
@font-face {
    font-family: 'Roboto';
    src: url("assets/font/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url("assets/font/Roboto-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url("assets/font/Roboto-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url("assets/font/Roboto-Thin.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
}
