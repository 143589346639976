.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .infinite {
    position: relative;
    min-width: 200px;
    width: 90%;
    height: 200px;
    background-color: #525252;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: perspective(500px) rotateX(30deg);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 10px;
      background: linear-gradient(90deg, #fff 0%,
              #fff 70%, #525252 70%, #525252 100%);
      background-size: 120px;
      animation: road 0.5s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 30px;
      background: #333;
      bottom: -30px;
      transform-origin: top;
      background-size: 120px;
      transform: perspective(500px) rotateX(-25deg);
    }

    .loadingText {
      font-size: 3rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &:after {
        content: 'Loading...';
        position: absolute;
        transform-origin: top;
        background-size: 120px;
        animation: road-text 3s linear infinite;
      }
    }

    .shadow {
      position: absolute;
      bottom: -93px;
      left: 50%;
      transform: translateX(-50%);
      width: 95%;
      height: 60px;
      background: linear-gradient(#333, transparent);
      opacity: 0.5;
    }
  }
}

@keyframes road {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -120px;
  }
}

@keyframes road-text {
  0% {
    left: -10ch;
  }
  100% {
    left: 110%;
  }
}
