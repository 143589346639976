.header {
  padding: 0 2rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .toggle {
    margin: 0 1rem;
    display: flex;
    align-items: center;
  }

  &.mobile {
    height: 3rem;
    justify-content: space-between;

    .toggle {
      order: 2;
      margin: 0;
    }
  }
}
