@import "../../../shared/theme/variables";

.link {
  font-weight: 500;
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 1rem;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: solid 3px $secondary-light;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;

  }

  &:hover:after, &.active:after {
    transform: scaleX(1);
  }
}
